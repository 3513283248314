<template>
  <div class="home-class">
    <div class="power-station">
      <!-- 头部部分 -->
      <div ref="search_d">
        <el-form :inline="true" :model="queryInfo.condition" class="demo-form-inline">
          <!-- <el-form-item label="电站名称">
            <el-select v-model="queryInfo.condition.powerStationCode" placeholder="请选择" clearable>
              <el-option v-for="item in powerStationList" :key="item.id" :label="item.powerStationName" :value="item.powerStationCode">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-select v-model="queryInfo.condition.dateFormat" placeholder="请选择" @change="formatChange">
              <el-option v-for="item in dateFormatList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          <el-form-item v-if="queryInfo.condition.dateFormat == '%Y-%m-%d'">
            <el-date-picker v-model="queryInfo.condition.summaryDate"
              type="date" value-format="yyyy-MM-dd"
              placeholder="选择日期" clearable
              :picker-options="pickerOptions"
              style="width: 100%;">
            </el-date-picker>
          </el-form-item>
          <el-form-item v-else>
            <el-date-picker v-model="queryInfo.condition.summaryDate"
              type="month" value-format="yyyy-MM" placeholder="选择年月"
              :picker-options="pickerOptions"
              clearable style="width: 100%;">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <!-- <el-button type="primary" @click="resert">重置</el-button> -->
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
        </el-form>
      </div>


      <!-- 表格部分 -->
      <div>
        <div class="hand-class">
          <img src="../../../assets/images/yunwei/export.png" @click="exportClick" alt="">
        </div>
        <el-table border ref="table" :data="tableData" tooltip-effect="dark" style="width: 100%" height="75vh">
          <!-- :height="height" -->
          <el-table-column label="中亮新能源" type="index" width="150" align="center"></el-table-column>

          <el-table-column prop="date" label="日期" align="center">
                 <el-table-column label="电站名称" align="center" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.powerStationName }}</template>
            </el-table-column>

            <el-table-column label="日期" align="center" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.date }}</template>
            </el-table-column>
            <el-table-column label="设备名称" align="center" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.deviceName }}</template>
            </el-table-column>
            <el-table-column label="设备编码" align="center" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.deviceSn }}</template>
            </el-table-column>
            <el-table-column label="发电量(kWh)" align="center" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.powerNum }}</template>
            </el-table-column>
            <el-table-column label="环比增减(%)" align="center" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.onMonth }}</template>
            </el-table-column>
            <el-table-column label="同比增减(%)" align="center" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.onYear }}</template>
            </el-table-column>
            <el-table-column label="有机功率(W)" align="center" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.efficiency }}</template>
            </el-table-column>
            <el-table-column label="单价(元)" align="center" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.price }}</template>
            </el-table-column>
          </el-table-column>


        </el-table>
        <!-- 分页 -->
        <!-- <div class="block">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryInfo.currPage" :page-sizes="[100, 200, 300, 400]" :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div> -->
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      labelType: 'add',
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 100,
        pageCount: 1,
        currPage: 1,
        condition: {
          dateFormat: '%Y-%m'
        }
      },
      userCode: JSON.parse(localStorage.getItem("userInfo")).userCode,
      form: {},
      powerStationList: [], //电站
      dateFormatList: [
        {label:'按月统计',value:'%Y-%m'},
        {label:'按日统计',value:'%Y-%m-%d'}
      ],
      showDialog: false,
      tableData: [],
      height: window.innerHeight - 290, //表格高度
      headerHeight: 0,
      pickerOptions:{
        disabledDate(time){
          // console.log('time',time,time.getTime() > Date.now(),time.getTime(), Date.now())
          return time.getTime() > Date.now();
        },
      },
    };
  },

  created() {
    this.getList()
    // this.getSelectList();
  },

  mounted() {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight;
      window.addEventListener('resize', this.getHeight())
    })
  },

  methods: {
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - this.headerHeight - 190;
    },
    formatChange(val){
      // this.queryInfo.condition.summaryDate = ''
    },
    getSelectList() {
      // 电站列表
      this.$http
      .post("powerStation/list", { currPage: 1, pageSize: 100 })
      .then((res) => {
        if (res.data.code == 200) {
          this.powerStationList = res.data.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    //获取数据 /dataStickEveryMonthPower/list
    getList() {
      var that = this;
      // this.queryInfo.condition.userCode = this.userCode;
      that.$http.post("/powerStationDeviceSummary/summaryByStation", that.queryInfo.condition).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          // that.total = response.data.data.count;
          // that.queryInfo.currPage = response.data.data.currPage
          that.tableData = response.data.data;
        }
      });
    },

    // 重置搜索
    resert() {
      this.queryInfo.condition = {}
    },

    search() {
      this.getList()
    },

    // 导出
    exportClick() {
      let that = this
      const _name = this.queryInfo.condition.dateFormat == '%Y-%m'?'设备发电量统计数据(按月统计).xlsx':'设备发电量统计数据(按日统计).xlsx'
      this.$http({method:'get',url:"powerStationDeviceSummary/exportSummaryByStation",responseType: "blob",params:{...that.queryInfo.condition}}).then((res)=>{
        // 创建一个新的URL代表指定的File对象或Blob对象
        const url = URL.createObjectURL(
          new Blob([res.data])
        );
        // 创建一个a标签用于下载
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", _name); // 下载文件名
        link.style.display = 'none'
        document.body.appendChild(link);
        link.click();

        // 清理
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }).catch((err)=>{
        console.log('???',err)
        // that.$message.error(err);
      })
    },

    // 保存
    handleSave() {
      var that = this;
      that.$http.post("/dataStickEveryMonthPower/save", that.form).then(function (response) {
        if (response.data.code == 200) {
          that.$notify.success({
            title: "提示",
            message: "保存成功",
            showClose: true,
          });
          that.getList()
        } else {
          that.$notify.info({
            title: "提示",
            message: response.data.message,
            showClose: true,
          });
        }
      }).catch(function (error) {


      })


    },

    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList()
    },

  },
};
</script>
<style scoped lang="scss">
.home-class {
  background: #223f6c;
  padding: 10px;
  height: 100%;

  .power-station {
    height: 100%;
    background: #2d5981;
    padding: 8px;
    overflow-y: auto;

    .hand-class {
      width: 100%;
      // display: flex;
      text-align: right;
      // float: right;
      margin-bottom: 5px;

      img {
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        margin: 0 5px;
      }
    }

    .demo-form-inline{
      .el-form-item{
        margin-bottom: 0;
      }
    }
    .form-class {
      ::v-deep .el-form-item__label {
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "微软雅黑", sans-serif;
        color: #333;
        text-align: left;
        line-height: normal;
        font-size: 16px;
      }

      ::v-deep .el-input__inner {
        border: 1px solid rgba(151, 179, 203, 0.55);
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #333;
        text-align: left;
        line-height: normal;
      }
    }

    ::v-deep .el-input-group__append {
      background: #409EFF;
      color: #fff;
    }

    /deep/ .el-form-item__label {
      background-color: rgba(255, 255, 255, 0);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
      font-size: 16px;
    }

    ::v-deep .el-input__inner {
      border: 1px solid rgba(151, 179, 203, 0.55);
      background-color: rgba(255, 255, 255, 0);
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      color: #f2f2f2;
      text-align: left;
      line-height: normal;
    }

    ::v-deep .el-table--border,
    .el-table--group {
      border: 1px solid rgba(151, 179, 203, 0.55);
    }

    ::v-deep .el-table--border th {
      background-color: #21527e;

      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }

    ::v-deep .el-table th {
      background-color: #2d5981;
      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }

    ::v-deep .el-table td {
      background-color: #2d5981;
      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }

    ::v-deep .el-table__body-wrapper {
      background: #2d5981;
    }

    ::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
      background-color: #223f6c !important;
    }

    ::v-deep .el-pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }

    ::v-deep .el-pagination button:disabled {
      background: rgba(255, 255, 255, 0);
    }

    ::v-deep .el-pager li.active {
      color: #f2f2f2;
      background-color: #00b2e6;
      cursor: default;
    }

    ::v-deep .el-pager li {
      background: none;
      color: #f2f2f2;
    }

    ::v-deep .el-icon {
      color: #fff;
    }

    ::v-deep .el-pagination .btn-prev {
      background: none;
    }

    ::v-deep .el-pagination .btn-next {
      background: none;
    }

    ::v-deep .el-pagination__total {
      color: #fff;
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      font-size: 12px;
    }

    ::v-deep .el-pagination__jump {
      color: #fff;
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      font-size: 15px;
    }

    ::v-deep .el-icon-close:before {
      color: #333;
    }

    ::v-deep .el-upload {
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
    }
  }
}
</style>
